/* eslint-disable global-require */
import React from 'react'
import './_newsletter.scss'

const Newsletter = () => (
  <section id="blogs-newsletter-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center blog-newsletter-img-wrap">
          <img className="img-fluid" src={require('../../../../static/blogs/newsletter/newsletter-img.png')} alt="news letter" />
        </div>

        <div className="col-lg-4 col-md-6 news-letter-wrap mt-auto mb-auto">
          <h1> Sign up for our newsletter</h1>
          <p>Stay updated with announcements on product releases, development updates.</p>

          <form className="newsletter-form">
            <div className="form-group mb-2 row">
              <div className="col-8 pr-0">
                <input type="text" className="form-control newsletter-email-field" placeholder="Your email" />
              </div>
              <div className="col-4 p-0">
                <button type="submit" className="btn mb-2 newsletter-submit-btn">Subscribe</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
)

export default Newsletter
