/* eslint-disable react/prop-types */
import React from "react";
import { graphql } from "gatsby";
import SecondaryLayout from "../components/layout/secondary-layout";
import SEO from "../components/seo";
import BlogBreadCrumb from "../components/blogs/breadcrumb";
import FeaturedBlogs from "../components/blogs/featured-blogs";
import Newsletter from "../components/blogs/newsletter-section/index";
import BlogsListing from "../components/blogs/blogs-listing";
import { filterLanguage } from "../components/utils/language";
const Blogs = ({ data }) => {
  const { nodes } = data.allContentfulBlog;
  const nodeslang = filterLanguage(false, nodes);
  const allBlogs = nodeslang;

  return (
    <SecondaryLayout location={false}>
      <SEO title="Blogs" />
      <BlogBreadCrumb />
      <FeaturedBlogs featureBlogs={allBlogs} />
      <Newsletter />
      <BlogsListing allBlogs={allBlogs} />
    </SecondaryLayout>
  );
};

export const query = graphql`
  {
    allContentfulBlog {
      nodes {
        id
        node_locale
        title
        slug
        authorImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        authorName
        articlePublishDate
        featuredImage {
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            url
            fileName
          }
        }
        category {
          title
        }
      }
    }
  }
`;
export default Blogs;
