/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable global-require */
import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import "./_featuredBlogs.scss";

const FeaturedBlogs = ({ featureBlogs, finnish = false }) => (
  <section id="featured-blogs-section">
    <div className="container">
      {featureBlogs.slice(0, 1).map((post) => (
        <div className="row animation-fade-in" key={post.id}>
          <div className="col-md-6 mt-md-0 mt-4">
            <Link
              className="post-feature-image"
              to={
                !finnish
                  ? `/blogs/${post.slug.toLowerCase()}`
                  : `/fi/blogs/${post.slug.toLowerCase()}`
              }
            >
              <Img
                fluid={post.featuredImage.fluid}
                alt={post.featuredImage.file.fileName}
              />
            </Link>
          </div>
          <div className="col-md-6 mt-md-0 mt-3 feature-post-category-tag">
            <ul>
              {post.category.map((category, index) => (
                <li key={index}>
                  <Link
                    to={
                      !finnish
                        ? `/blogs/${category.title
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`
                        : `/fi/blogs/${category.title
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`
                    }
                  >
                    {category.title}
                  </Link>
                </li>
              ))}
            </ul>
            <Link
              className="post-blog-title"
              to={
                !finnish
                  ? `/blogs/${post.slug.toLowerCase()}`
                  : `/fi/blogs/${post.slug.toLowerCase()}`
              }
            >
              <h1>{post.title}</h1>
            </Link>
            <div className="post-author-wrap mt-lg-4 d-flex">
              <Img
                style={{
                  position: "relative",
                  height: "34px",
                  width: "34px",
                  borderRadius: "50%",
                }}
                fluid={post.authorImage.fluid}
                alt="author"
              />
              <div className="author-name-and-publish ml-3">
                <h5>{post.authorName}</h5>
                <p>{post.articlePublishDate}</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="row">
        {featureBlogs.slice(1, 4).map((post) => (
          <div
            className="col-md-4 mt-md-5 mt-4 animation-fade-in"
            key={post.id}
          >
            <Link
              className="post-feature-sec-image"
              to={
                !finnish
                  ? `/blogs/${post.slug.toLowerCase()}`
                  : `/fi/blogs/${post.slug.toLowerCase()}`
              }
            >
              <Img
                fluid={post.featuredImage.fluid}
                alt={post.featuredImage.file.fileName}
              />
            </Link>
            <div className="row mt-3">
              <div className="col-12">
                <ul className="post-category-sec-tag">
                  {post.category.map((category, index) => (
                    <li key={index}>
                      <Link
                        to={
                          !finnish
                            ? `/blogs/${category.title
                                .replace(/\s+/g, "-")
                                .toLowerCase()}`
                            : `/fi/blogs/${category.title
                                .replace(/\s+/g, "-")
                                .toLowerCase()}`
                        }
                      >
                        {category.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <Link
                  className="post-blog-sec-title"
                  to={
                    !finnish
                      ? `/blogs/${post.slug.toLowerCase()}`
                      : `/fi/blogs/${post.slug.toLowerCase()}`
                  }
                >
                  <h1>{post.title}</h1>
                </Link>
                <div className="post-author-sec-wrap mt-lg-4 d-flex">
                  <Img
                    style={{
                      position: "relative",
                      height: "34px",
                      width: "34px",
                      borderRadius: "50%",
                    }}
                    fluid={post.authorImage.fluid}
                    alt="author"
                  />
                  <div className="sec-author-name-and-publish ml-3">
                    <h5>{post.authorName}</h5>
                    <p>{post.articlePublishDate}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default FeaturedBlogs;
